import React from "react";
import {
  GoogleMap,
  GoogleMapProps,
  useLoadScript
} from "@react-google-maps/api";

const defaultCenter = { lat: 9.06696, lng: 7.39115 };

const options = {
  disableDefaultUI: true,
  scaleControl: true,
  mapTypeId: "roadmap",
  labels: true
};

export type MapType = Parameters<NonNullable<GoogleMapProps["onLoad"]>>[0];

type MapProps = React.PropsWithChildren<{
  setMap: (map: MapType) => void | Promise<void>;
}>;

export default function Map(props: MapProps) {
  const { setMap, children } = props;
  const { isLoaded, loadError } = useLoadScript({
    // googleMapsApiKey: "AIzaSyDX6o8zSFDpNRCvdEY9mCMy7z6v9fCYJkY"
    googleMapsApiKey: "AIzaSyAuJmvhZ3HbPs8Jw92JEDqwFL44wDtz1T4"
  });

  const renderMap = () => {

    const loadHandler = (map: MapType) => {
      setMap(map);
    };

    return (
      <GoogleMap
        id="circle-example"
        mapContainerStyle={{
          height: "550px",
          width: "1200px"
        }}
        zoom={11}
        center={defaultCenter}
        options={options}
        onLoad={loadHandler}
      >
        {children}
      </GoogleMap>
    );
  };

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return isLoaded ? renderMap() : <div>Loading...</div>;
}
