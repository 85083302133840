import { combineReducers } from 'redux';
import counterReducer from "./features/counterSlice";
import glnReducer from "./features/glnSlice";

const rootReducer = combineReducers({
  counter: counterReducer,
  glnBySector: glnReducer,
});

export default rootReducer;
