import React from 'react';
import "./style.css";
import { useAppSelector } from '../../redux/hooks';

const Accordion = () => {
  const glns = useAppSelector((state) => state.glnBySector);

  return (
    <React.Fragment>
        <div className="container overflow-y-auto " style={{height: "345px"}}>
            <div className="row">
                <div className="rounded border overflow-hidden shadow-sm bg-white">
                    {glns.returnData.map((data, i)=> (
                        <div key={i} className="w-100 text-white overflow-hidden">
                            <input type="checkbox" className='customInput' id={`${i}`} />
                            <label className="custom-tab-label border-bottom" htmlFor={`${i}`}>
                                <span> <button disabled className="btn btn-light btn-sm shadow">{data.organizationLegalName}</button> </span> <button disabled className="btn btn-light shadow btn-sm">gln - {data.gln}</button>
                            </label>
                            <div className="tab-content">
                                <p>{data.glnType}</p>
                                <p>{data.organizationSector}</p>
                                <p>{data.organizationSubSector}</p>
                                <p>{data.email}</p>
                                <p>{data.phoneNumber}</p>
                                <p>{data.functionName}</p>
                                <p>{data.url}</p>
                                <p>{data.state}</p>
                                <p>{data.localAreaGovt}</p>
                                <p>{data.street}</p>
                                <p>{data.city}</p>
                                <p>{data.postalCode}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </React.Fragment>
  )
}

export default Accordion