import React from 'react';
import Accordion from './accordion'
import {FaSearchLocation, FaPlusCircle} from 'react-icons/fa';
import { setSector, setGLNNumber } from '../redux/features/glnSlice';
import { useAppDispatch, useAppSelector } from '../redux/hooks';

const Category = () => {
    const glns = useAppSelector((state) => state.glnBySector);
    const dispatch = useAppDispatch();

    const rowStyles: React.CSSProperties = {
        display: 'flex',
        gap: '1em',
        overflowX: 'auto',
        scrollSnapType: 'x mandatory',
      };


    let filterSector = [
        {
            "name": "HEALTHCARE",
            "sector": "HealthCare",
        }, 
        {
            "name": "FMCG",
            "sector": "FMCG",
        }, 
        {
            "name": "TRANSPORT AND LOGISTICS",
            "sector": "TransportationAndLogistics",
        }, 
        {
            "name": "FOOD SERViCE",
            "sector": "FoodService",
        }, 
        {
            "name": "TECHNICAL INDUSTRY",
            "sector": "TechnicalIndustry",
        }
    ];

    const handleClick = (value:string) =>{
        if (glns.sector === value){
            dispatch(setSector(""));
        } else {
            dispatch(setSector(value));
        }
    }

    const handleInputChange2 = (event: React.ChangeEvent<HTMLInputElement>):void => {
        dispatch(setGLNNumber(event.target.value))
    }

  return (
    <div className='container mt-3'>
        <div className="row ">
            <div className="input-group mt-2">
                <input
                    type="text"
                    className="form-control bg-white shadow-sm p-3" 
                    placeholder="Scan GLN / Enter GLN"
                    value={glns.GLNNumber}
                    onChange={handleInputChange2}
                />
                <button className='btn' style={{color: "navy"}} type="submit">
                    <FaSearchLocation className='fs-3'/>
                </button>
            </div>
        </div>

        <div className='bg-white p-3 my-3' style={rowStyles}>
            {filterSector.map((category, i) => (
                <div 
                    className={`p-2 border bg-${glns.sector === category.sector ? "info" : "light"} text-sm-center`} 
                    onClick={() => handleClick(category.sector)}
                    key={i}
                >
                    <FaPlusCircle className='fs-2 text-danger mx-4'/> <br/>
                    <span style={{fontSize: "12px"}}>{category.name}</span>
                </div>
            ))}
            
        </div>


        <div className="row">
            <Accordion/>
        </div>
    </div>
  )
}

export default Category