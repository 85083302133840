import React from 'react';
import "./LoadingScreen.css";

interface LoadingScreenProps {
  text?: string;
}

const LoadingScreen: React.FC<LoadingScreenProps> = ({ text = 'Loading...' }) => {
  return (
    <div className="loading-screen">
      <div className="loading-spinner"></div>
      <p>{text}</p>
    </div>
  );
};

export default LoadingScreen;
