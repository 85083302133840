import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { glnReturnType } from "../types";

interface GlnMainType {
  returnData: glnReturnType[];
  totalItems: number; 
  pageCount: number; 
  loading: boolean;
  sector: string;
  GLNNumber: string;
}

const initialState: GlnMainType = {
  returnData: [],
  totalItems: 0,
  pageCount: 0,
  loading: false,
  sector: "",
  GLNNumber: "",
};

export const fetchGln = createAsyncThunk(
  "glnBySector/All", async ( { currentPage, currentSector }: { currentPage: number; currentSector: string; } ) => {
  const response = await axios.get(
    `https://stagging-glnregistryservices.gs1ng.org/api/OrganizationDetails/GLNS-by-Sector?pageNumber=${currentPage}&pageSize=5${currentSector? `&sector=${currentSector}` : ''}`
  );
  const data = response.data.data as glnReturnType[];
  const totalItems = response.data.totalItems; 
  return { data, totalItems }; 
});

export const fetchGlnByNum = createAsyncThunk(
  "fetch/fetchByNum", async ( { currentGLN }: { currentGLN: string } ) => {

    // console.log("xcxc", currentGLN)
  const response = await axios.get(
    `https://stagging-glnregistryservices.gs1ng.org/api/OrganizationDetails/GLNS-by-Sector?pageNumber=1&pageSize=5${currentGLN? `&GLNNumber=${currentGLN}` : ''}`
  );
  const data = response.data.data as glnReturnType[];
  // debugger;
  const totalItems = response.data.totalItems; 
  return { data, totalItems }; 
});


const glnSlice = createSlice({
  name: "glnBySector",
  initialState,
  reducers: {
    setSector: (state, action) => {
      state.sector = action.payload;
    },
    setGLNNumber: (state, action) => {
      state.GLNNumber = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGln.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchGln.fulfilled, (state, action) => {
        state.loading = false;
        state.returnData = action.payload.data;
        state.pageCount = Math.ceil(action.payload.totalItems / 5);
        
      })
      .addCase(fetchGln.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchGlnByNum.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchGlnByNum.fulfilled, (state, action) => {
        state.loading = false;
        state.returnData = action.payload.data;
        state.pageCount = Math.ceil(action.payload.totalItems / 5);
        
      })
      .addCase(fetchGlnByNum.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { setSector, setGLNNumber } = glnSlice.actions;
export default glnSlice.reducer;
