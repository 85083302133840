import React from 'react'
import { MarkerClusterer } from "@react-google-maps/api";
import Map, { MapType } from "../components/Map";
import CustomMarker from "../components/CustomMarker";
import Category from "../components/Category";
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { fetchGln, fetchGlnByNum } from '../redux/features/glnSlice';
import ReactPaginate from 'react-paginate';
import LoadingScreen from '../components/LoadingScreen/LoadingScreen';
import logoGs1 from "../img/gs1.png";

const MapPage = () => {
  const glns = useAppSelector((state) => state.glnBySector);
  const dispatch = useAppDispatch();
  
  const [map, setMap] = React.useState<MapType | null>(null);

  React.useEffect(() => {
    function mapFitBounds() {
      if (!map || glns.returnData.length === 0) return;

      const bounds = new google.maps.LatLngBounds();
      glns.returnData.forEach((loc) =>
        bounds.extend(new google.maps.LatLng(parseFloat(loc.latitude), parseFloat(loc.longitude)))
      );

      map.fitBounds(bounds);
    }

    if (map) {
      // map.panTo(...)
      mapFitBounds();
    }
  }, [map]);

  React.useEffect(() => {
    if(glns.GLNNumber===''){
      dispatch(fetchGln({ currentPage: 1, currentSector: glns.sector }));
    }else{
      const getData=setTimeout(() => {
          console.log("handler2 .......................................")
          dispatch(fetchGlnByNum({ currentGLN: glns.GLNNumber }));
      }, 3000);

      return ()=>clearTimeout(getData)
    }
  }, [glns.sector, glns.GLNNumber]);
  
  const handlePageClick = async (data: { selected: number }) => {
    const currentPage = data.selected + 1;
    dispatch(fetchGln({ currentPage, currentSector: glns.sector }));
  };
  

  return (
    <div className="container-fluid">
      {glns.loading? <LoadingScreen/> :
      <>
        <div className="row">
          <div className="d-flex justify-content-between align-items-center text-white" style={{background: "navy"}}>
            <img src={logoGs1} alt="" className='my-4' style={{height: "3rem", width: "4.8rem"}}/>
            <div className="fw-bold text-uppercase fs-1">GLN Registory Browser</div>
            <div className=""></div>
          </div>
        </div>
        <div className="row gx-2">
          <div className="col-md-6">
            <div className="px-3 bg-light">
              <Category/>
            </div>
          </div>
          <div className="col-md-6">
            <div className="bg-light overflow-hidden mt-3" style={{height: "550px"}}>
            {glns.loading ? (
                <p>Loading...</p>
              ) : (
                <Map setMap={setMap}>
                  <MarkerClusterer>
                    {(clusterer) => (
                      <>
                        {glns.returnData.map((loc, i) => (
                          <CustomMarker
                            key={i}
                            position={{ lat: parseFloat(loc.latitude), lng: parseFloat(loc.longitude) }}
                            clusterer={clusterer}
                            infoContent= {loc.organizationLegalName}
                          />
                        ))}
                      </>
                    )}
                  </MarkerClusterer>
                </Map>
              )}
            </div>
          </div>
        </div>
      </>
      }
      <div className="row mt-4">
        <ReactPaginate
          previousLabel={"previous"}
          nextLabel={"next"}
          breakLabel={"..."}
          pageCount={glns.pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination justify-content-center"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
        />
      </div>
    </div>
  )
}

export default MapPage